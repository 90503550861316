const initialProps = {
    statusLoading: true
};

export default function(state = initialProps, action) {
    switch (action.type) {
        case "STATE_STATUS_LOADING":
            return {
                ...state,
                statusLoading: action.payload
            };
        default:
            return state;
    }
}