import React, { useState, useEffect } from "react";
import { Row, Layout } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Utils
import firebase from "../utils/firebase";
import "firebase/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openCloseStatusLoginAction} from "../actions/StatusLoginActions";
import { openCloseLoading } from '../actions/LoadingActions';

// Componentes
import Loading from "../components/Loading";
import MenuTabFooter from "../components/MenuTab";
import HeaderTabBh from "../components/Header";

// Button Actions
import ButtonActionsUtils from "../components/ButtonActions";
import ButtonActionsBrokerhood from "../components/ButtonActions/ButtonActionsBrokerhoods";
import RegisterForm from "../pages/Account/Register";

// Paginas
import BrokerhoodPage from "./Brokerhood";
import Auth from "./Auth";
import UploadInvetory from "./utils/UploadInventory";
import MyBrokerHoods from "./Brokerhood/MyBrokerHoods";

//Imagenes y logos
import bg3 from "../assets/img/bh-bg.png";
import bg1 from "../assets/img/bg-4.png";

// Estilos
import "./Home.scss";
import "antd/dist/antd.css";

export default function Brokerhood() {
  const { Footer, Content } = Layout;

  // Dispatch para ejecutar acciones de redux
  const dispatch = useDispatch();
  const IsLogin = state => dispatch(openCloseStatusLoginAction(state));
  const setStatusLoadingPage = state => dispatch(openCloseLoading(state));

  // Redux Extraer el Estado actual
  const stateBAU = useSelector((state) => state.buttonutils.statusButtonUtils);
  const isLogin = useSelector((state) => state.login.statusLogin);
  const isLoadingPage = useSelector((state) => state.loading.statusLoading);


  useEffect(() => {
    (async () => {

      firebase.auth().onAuthStateChanged((currentUser) => {
        IsLogin(currentUser ? true : false); // cambio esl estatus del login
        setStatusLoadingPage(false); // Muestro loading
      });

    })();
  }, []);

  return (
    <>

    {isLoadingPage ? (<Loading />):(

    <Router>
      <Layout>
        <HeaderTabBh />
        <Row>
          <Content class="col-4">
            <Switch>
              <div className="side-content-app">
                <Route path="/" exact={true}>
                  {isLogin ? <BrokerhoodPage /> : <Auth />}
                </Route>
                <Route path="/matches" exact={true}>
                  <div
                    className="bg-backgr-app"
                    style={{ backgroundImage: `url('${bg1}')` }}
                  ></div>
                </Route>
                <Route path="/brokerhoods" exact={true}>
                  <ButtonActionsBrokerhood />
                </Route>
                <Route path="/gestion" exact={true}>
                  < MyBrokerHoods />
                </Route>
              </div>
            </Switch>
          </Content>
          <Content class="col-8">
            <Switch>
              <div
                className="side-back-img-left"
                style={{ backgroundImage: `url('${bg3}')` }}
              >
                <div className="side-content-left">
                  <Route path="/auth">
                    <div className="side-text-content">
                      <h3 className="wcome-title">
                        OPTIMIZA, PERSONALIZA, INCREMENTA
                      </h3>
                      <h4 className="wcome-subtitle">
                        TU TIEMPO, TUS ANUNCIOS, TUS RESULTADOS!
                      </h4>
                    </div>
                  </Route>
                  <Route path="/upload-inventory" exact={true}>
                    <UploadInvetory />
                  </Route>
                  <Route path="/register-user" exact={true}>
                    <div className="side-text-content">
                      <h3 className="wcome-title">
                        OPTIMIZA, PERSONALIZA, INCREMENTA
                      </h3>
                    </div>
                  </Route>
                </div>
                <div className="copy-foot">
                  &copy; Powered by AAL-Team, S.A.
                </div>
              </div>
            </Switch>
          </Content>
        </Row>
        {isLogin ? (
          <Footer>
            <MenuTabFooter />
          </Footer>
        ) : (
          <></>
        )}
        {stateBAU ? <ButtonActionsUtils /> : <></>}

      </Layout>
    </Router>

    )}
    </>
  );
}
