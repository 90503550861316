/*
import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDQTtZlF9vUUOzzv_S_s6EJszTuN7dxzrk",
    authDomain: "blog-7a2ef.firebaseapp.com",
    databaseURL: "https://blog-7a2ef.firebaseio.com",
    projectId: "blog-7a2ef",
    storageBucket: "blog-7a2ef.appspot.com",
    messagingSenderId: "84538451173",
    appId: "1:84538451173:web:ebf98e8233621d6321600c"
  };
  // Initialize Firebase
  export default firebase.initializeApp(firebaseConfig);

*/
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBbbqRlLKh58Gboy-73McYBqlQREL84xqU",
  authDomain: "brokerhood-fe2a6.firebaseapp.com",
  databaseURL: "https://brokerhood-fe2a6.firebaseio.com",
  projectId: "brokerhood-fe2a6",
  storageBucket: "brokerhood-fe2a6.appspot.com",
  messagingSenderId: "686257723652",
  appId: "1:686257723652:web:4a01e166a75dc34ea2d851",
  measurementId: "G-MKGFTFL9CJ",
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
