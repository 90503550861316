import React, { useState, useEffect } from "react";
import { ListItem } from "@material-ui/core";
import {Icon, Image} from '@mdi/react';
import { 
        mdiAccountGroupOutline
} from '@mdi/js'


export default function UserLogued(props) {
  const { gestionGen, navigation } = props;
  const [curGestion, setCurGestion] = useState({});
  console.log(props);

  useEffect(() => {
    setCurGestion(gestionGen[0]);
  }, []);

  return (
    <div >
      <Image
        source={require("../../../assets/img/brokerhood.png")}
        resizeMode="cover"
      >
        <div style={{ marginTop: 20 }}>
          <Image
            source={require("../../../assets/img/logo-login.png")}
            resizeMode="contain"
          />
          <ListItem
            key={0}
            title={"Comunidad Brokers " + curGestion.brokers_gen}
            leftIcon={
              <Icon path={mdiAccountGroupOutline}
              size={1}
              />
            }
            bottomDivider
          />
          <ListItem
            key={1}
            title={"BrokerHoods " + curGestion.brokershood_gen}
            leftIcon={
              <Icon path={mdiAccountGroupOutline}
              size={1}
              />
            }
            bottomDivider
          />
          <ListItem
            key={2}
            title={"Ofertas Totales " + curGestion.ofertas_gen}
            leftIcon={
              <Icon path={mdiAccountGroupOutline}
              size={1}
              />
            }
            bottomDivider
          />
          <ListItem
            key={3}
            title={"Pedidos Totales " + curGestion.pedidos_gen}
            leftIcon={
              <Icon path={mdiAccountGroupOutline}
              size={1}
              />
            }
            bottomDivider
          />
          <ListItem
            key={4}
            title={"Negocios Cerrados " + curGestion.hits_gen}
            leftIcon={
              <Icon path={mdiAccountGroupOutline}
              size={1}
              />
            }
            bottomDivider
          />
        </div>
      </Image>
    </div>
  );
}
