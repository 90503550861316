import React, { useEffect } from "react";

// Utils
import { reloadData } from "../../utils/ReloadEnv";
import { showNotification } from "../../utils/showNotification";

import logo from "../../assets/img/brokerhood.png";

export default function BrokerhoodPage() {

    return (
    <div className="logo">
        <img src={logo} className="img-logo" />
    </div>);
    
}