export const openCloseEditPerfilModalAction = state => {
    return {
        type: "STATE_MODAL_EDIT_PERFIL",
        payload: state
    };
};

export const openCloseChangeAvatarModalAction = state => {
    return {
        type: "STATE_MODAL_CHANGE_AVATAR",
        payload: state
    };
};

export const openCloseChangePasswordModalAction = state => {
    return {
        type: "STATE_MODAL_CHANGE_PASSWORD",
        payload: state
    };
};