import React, { useState } from "react";
import { Input, Form, Button, Divider } from "antd";
import RegisterForm from "../Register";

// Importo iconos Material Community
import Icon from "@mdi/react";
import { mdiAt, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";

// constantes
import { colorPrm } from "../../../constants";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openCloseStatusLoginAction } from "../../../actions/StatusLoginActions";
import { openCloseLoading } from "../../../actions/LoadingActions";

//Utils
import { showNotification } from "../../../utils/showNotification";
import { validateEmail } from "../../../utils/Validation";
import { reloadData } from "../../../utils/ReloadEnv";
import firebase from "../../../utils/firebase";
import "firebase/auth";

// Estilos
import "./Login.scss";

const Login = (props) => {
  const { setSelectedForm } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(defaultValueForm());
  const [formError, setFormError] = useState({});
  const [isRegister, setIsRegister] = useState(false);

  // Dispatch para ejecutar acciones de redux
  const dispatch = useDispatch();
  const IsLogin = (state) => dispatch(openCloseStatusLoginAction(state));
  const setStatusLoading = (state) => dispatch(openCloseLoading(state));

  // Redux Extraer el Estado actual
  const isLogin = useSelector((state) => state.login.statusLogin);
  const isLoading = useSelector((state) => state.loading.statusLoading);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOK = {};

    if (!validateEmail(formData.email)) {
      errors.email = true;
      formOK = false;
    }

    if (formData.password.length < 6) {
      errors.password = true;
      formOK = false;
    }

    setFormError(errors);

    if (formOK) {
      setStatusLoading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(formData.email, formData.password)
        .then(() => {
          IsLogin(true); // login activo
          setStatusLoading(false); // loadnig falso
          showNotification(
            4,
            "SESION INICIADA",
            "Usuario logueado correctamente."
          );
        })
        .catch(() => {
          showNotification(
            2,
            "DATOS INCORRECTOS",
            "Email o Contraseñas Incorrectos"
          );
          setStatusLoading(false); // loadnig falso
        });
    }
  };

  return (
    <div className="login-form">
      <Form onChange={onChange} onClick={onSubmit}>
        <Form.Item>
          <Input
            name="email"
            placeholder="Correo Electronico"
            className="input"
            error={formError.email}
            suffix={<Icon path={mdiAt} size={0.8} color={"#666"} />}
          />
          {formError.email && (
            <span className="error-text">
              Por favor, ingrese un correo electronico válido.
            </span>
          )}
        </Form.Item>
        <Form.Item>
          <Input.Password
            name="password"
            placeholder="Contraseña"
            className="input"
            error={formError.password}
            iconRender={(visible) =>
              visible ? (
                <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
              ) : (
                <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
              )
            }
          />
          {formError.password && (
            <span className="error-text">
              Debes ingresar una contraseña superior a 5 caracteres.
            </span>
          )}
        </Form.Item>
        <Button
          className="login"
          style={{ backgroundColor: colorPrm }}
          type="submit"
          htmlType="button"
          block
        >
          Iniciar Sesión
        </Button>
      </Form>

      <div className="login-form__options">
        <p>
          {" "}
          ¿No tienes cuenta?{" "}
          <span onClick={() => setSelectedForm("register")}>
            Compra una licencia de Brokerhood
          </span>
        </p>
      </div>
      {isRegister ? LoadRegister() : <></>}
    </div>
  );
};

export default Login;
// <p onClick={() => setSelectedForm(null)}>Volver</p>
function defaultValueForm() {
  return {
    email: "",
    password: "",
  };
}

function LoadRegister() {
  return <div>FUNCION LLAMADA REGISTRO</div>;
}
