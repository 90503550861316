import React, { useState } from "react";

import Icon from '@mdi/react'
import { mdiAccountGroupOutline, mdiArrowCollapseAll, mdiStarOutline, mdiHammerWrench } from '@mdi/js'

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { changeStatusBAU } from "../../actions/ButtonUtilsActions";

// css
import "./MenuTab.scss";

// Importo Constantes del Sistema
import { colorPrm, colorSec } from "../../constants";

export default function MenuTab() {
    const [value, setValue] = useState('/');

    // Dispatch para ejecutar acciones de redux
    const dispatch = useDispatch();
    const statusButtonUtils = state => dispatch(changeStatusBAU(state));
    // Redux Extraer el Estado actual
    const stateBAU = useSelector(state => state.buttonutils.statusButtonUtils);

    // Accion de valores de los Menu
    const handleChange = (event, newValue) => {
        setValue(newValue);

        // Activacion de los Button Actions
        if(newValue == "utils"){
            statusButtonUtils(!stateBAU);
        }else{
            statusButtonUtils(false);
        }

    };

    return (
        <BottomNavigation value={value} onChange={handleChange} showLabels className="main-menu-tab">
        <BottomNavigationAction
            label="B-Hoods"
            component={Link}
            to="brokerhoods"
            icon={<Icon path={mdiAccountGroupOutline}
                    title="B-Hoods"
                    size={1}
                    horizontal
                    color={colorSec}
                    />} />
        <BottomNavigationAction
            label="Matches"
            component={Link}
            to="matches"
            icon={<Icon path={mdiArrowCollapseAll}
                    title="Matches"
                    size={1}
                    horizontal
                    color={colorPrm}
                    />} />
        <BottomNavigationAction
            label="MVB"
            component={Link}
            to="mvb"
            icon={<Icon path={mdiStarOutline}
                    title="MVB"
                    size={1}
                    horizontal
                    color={colorPrm}
                    />} />
        <BottomNavigationAction
            label="Utils"
            value="utils"
            icon={<Icon path={mdiHammerWrench}
                    title="Utils"
                    size={1}
                    horizontal
                    color={colorPrm}
                    />} />
        </BottomNavigation>
    );

}

//https://materialdesignicons.com/