const initialProps = {
    stateModalEditPerfil: false,
    stateModalChangeAvatar: false,
    stateModalChangePassword: false,
    stateModal: false
};

export default function(state = initialProps, action) {
    switch (action.type) {
        case "STATE_MODAL_EDIT_PERFIL":
            return {
                ...state,
                stateModalEditPerfil: action.payload,
                stateModal: true
            };
        case "STATE_MODAL_CHANGE_AVATAR":
            return {
                ...state,
                stateModalChangeAvatar: action.payload,
                stateModal: true
            }
        case "STATE_MODAL_CHANGE_PASSWORD":
            return {
                ...state,
                stateModalChangePassword: action.payload,
                stateModal: true
            }
        default:
            return state;
    }
}