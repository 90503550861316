import React from 'react';
import { Link } from "react-router-dom";
import { Container, Button, Link as Linkba, lightColors, darkColors } from "react-floating-action-button";
/* Carga de Iconos Material Community */
import Icon from '@mdi/react'
import { 
    mdiCloudSearchOutline, 
    mdiClose,
    mdiAccountGroupOutline,
    mdiCityVariantOutline,
    mdiHomeCityOutline
} from '@mdi/js'

// constantes
import { colorPrm } from "../../constants";

/* Estilos personalizados Buttons Actions */
import "./ButtonActions.scss";

export default function ButtonActBrokerHood() {

    return (
    <Container>
        <Linkba
            styles={{ backgroundColor: "#3CBA54", color: lightColors.white }}
            tooltip="Gestion"
             ><Link to="/gestion" className="link-ba"><Icon path={ mdiCloudSearchOutline }
             size={1}
             /></Link>
        </Linkba>
        <Linkba
            styles={{backgroundColor: "#65A4CA", color: lightColors.white}}
            tooltip="Peticion"
             ><Link to="/upload-inventory" className="link-ba"><Icon path={mdiHomeCityOutline}
             size={1}
             /></Link>
        </Linkba>
        <Linkba className="link-ba"
            styles={{backgroundColor: "#E3645D", color: lightColors.white}}
            tooltip="Ofertas"
             ><Link to="/download-inventory" className="link-ba"><Icon path={mdiCityVariantOutline}
             size={1}
             /></Link>
        </Linkba>
        <Linkba className="link-ba"
            styles={{ backgroundColor: "#3B73CE", color: lightColors.white }}
            tooltip="B-Hoods"
            ><Link to="/download-inventory" className="link-ba"><Icon path={mdiAccountGroupOutline}
            size={1}
            /></Link>
        </Linkba>
        <Linkba className="link-ba"
            styles={{ backgroundColor: "#F7DC6F", color: lightColors.white }}
            tooltip="Invitaciones"
            ><Link to="/download-inventory" className="link-ba"><Icon path={mdiAccountGroupOutline}
            size={1}
            /></Link>
        </Linkba>
        <Button
            tooltip="Mas Acciones!"
            rotate={true}
            styles={{backgroundColor: colorPrm, color: lightColors.white}}
            ><Icon path={mdiClose}
            size={1}
            horizontal
        /></Button>
    </Container>

    );

}