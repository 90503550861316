import React, {useState} from 'react';

import Home from "./pages/Home";

// Redux
import store from "./Store";
import { Provider } from 'react-redux';

export default function Brokerhood() {
  
  return (<Provider store={store} ><Home /></Provider>);

}