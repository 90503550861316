import React, { useState, useEffect } from "react";
import { Row, Input, Form, Dropdown, Button, List } from "antd";

import { Avatar, Badge } from "@material-ui/core";

// Importo iconos Material Community
import Icon from "@mdi/react";
import {
  mdiPencilCircle,
  mdiAccountCircleOutline,
  mdiShieldAccountOutline,
  mdiPhone,
  mdiSeatReclineExtra,
  mdiGoogleMaps,
  mdiEyeOutline,
  mdiEyeOffOutline
} from "@mdi/js";

import ImageUploader from "react-images-upload";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openCloseEditPerfilModalAction, openCloseChangeAvatarModalAction, openCloseChangePasswordModalAction } from "../../actions/modalActions";
import { openCloseLoading } from "../../actions/LoadingActions";

// Constantes
import { AVATAR_DEFAULT, USER_INFO, USER_BHOODS, colorPrm, colorSec } from "../../constants";

// Util
import SRV from "../../utils/Service";
import { reloadData } from "../../utils/ReloadEnv";
import { getItem, updateItem, deleteItem } from "../../utils/Storage";
import { showNotification } from "../../utils/showNotification";
import Modal from "../../components/Modal";
import firebase from "../../utils/firebase";
import "firebase/auth";

// Estilos
import "./Header.scss";

// Imagenes
import logo_menu_top from "../../assets/img/logo-menu.png";
import bg3 from "../../assets/misc/bg-1.jpg";

export default function Headers() {
  const [avatar, setAvatar] = useState(AVATAR_DEFAULT);
  const [pictures, setPictures] = useState([]);
  const [newName, setNewName] = useState("");
  const [newCompany, setNewCompany] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newTele, setNewTele] = useState("");
  const [newCargo, setNewCargo] = useState("");
  const [newCiudad, setNewCiudad] = useState("");
  const [antPassword, setAntPassword] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoadingButton] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [currBroker, setCurrBroker] = useState("");
  const [uid, setUid] = useState();

  // Dispatch para ejecutar acciones de redux
  const dispatch = useDispatch();
  const OpenModalEditPerfil = (state) => dispatch(openCloseEditPerfilModalAction(state));
  const OpenModalAvatar = (state) => dispatch(openCloseChangeAvatarModalAction(state));
  const OpenModalChangePassword = (state) => dispatch(openCloseChangePasswordModalAction(state));
  const setStatusLoadingPage = (state) => dispatch(openCloseLoading(state));

  // Redux Extraer Status
  const isLogin = useSelector((state) => state.login.statusLogin);
  const isLoadingPage = useSelector((state) => state.loading.statusLoading);
  const stateModalPerfilEdit = useSelector((state) => state.modals.stateModalEditPerfil);
  const stateModalEditAvatar = useSelector((state) => state.modals.stateModalChangeAvatar);
  const stateModalChangePassword = useSelector((state) => state.modals.stateModalChangePassword);

  useEffect(() => {

      if(isLogin){ // Solo si esta login realizo la carga del superobjeto y la informacion del Usuario
        (async () => {
            let reLoad = await reloadData(); // cargo el SuperObjeto
            console.log('cargando SuperObjeto');

            if(reLoad){ //valido si el superobjeto esta creado
              traerBroker(); // trae los valores almacenados en el localstorage del usuario
            }

          })();
      }

  }, [isLogin]);

  ////////////////////////////////////////////////
  // Funcion para traer la Info del Broker Actual
  const traerBroker = async () => {
    let cur_brk = await getItem(USER_INFO);
    cur_brk = JSON.parse(cur_brk);
    console.log('inicio carga de info del usuario');

    setCurrBroker(cur_brk);
    setUid(cur_brk.id_mobile);
    setNewName(cur_brk.brk_name);
    setNewCompany(cur_brk.brk_company);
    setNewEmail(cur_brk.brk_mail);
    setNewTele(cur_brk.brk_telefono);
    setNewCiudad(cur_brk.brk_ciudad);
    setNewCargo(cur_brk.brk_cargo);
    setAvatar(cur_brk.brk_avatar);
  };

  
  // Funcion para mostrar el modal de la informacion del usuario
  const ModalEditPerfil = () => {
      OpenModalEditPerfil(true); // Visualizo el Modal de Editar Perfil
  };

  // Funcion para mostrar el Modal de Modificar Avatar
  const ModalChangeAvatar = () => {
    OpenModalAvatar(true);
  }

  // Funcion para mostrar el Modal de Modificar Password
  const ModalChangePassword = () => {
    OpenModalChangePassword(true);
  }

  ////////////////////////////////
  // Funcion para Cerrar Session
  const LogOut = async() => {
    setStatusLoadingPage(true); // Muestra loading
    const usrResult = await deleteItem(USER_INFO); // borrado de LocalStorage de Info del Usuario
    console.log('elimino datos de usuario');

    if (usrResult) {
      const bkhResult = await deleteItem(USER_BHOODS);
      console.log('elimino datos de BH');
      firebase.auth().signOut(); // Cerrar Sesion de Usuario den Firebase
      setAvatar(AVATAR_DEFAULT); // Cambio el Avata a Default
      setStatusLoadingPage(false); // Cierra loading

    }


  };


  ////////////////////////////////////////////
  // Funcion para actualizar datos del usuario
  const updateNewName = async () => {
    setError({});
    if (!newName || !newCompany || !newCargo || !newTele) {
      let objError = {};
      !newName && (objError.nombre = "No puede estar vacio");
      !newCompany && (objError.company = "Debe registrar la compañia");
      !newCargo && (objError.cargo = "Registre el cargo ocupado");
      !newTele && (objError.telefono = "Registre numero de contacto");
      setError(objError);
    } else {
      setIsLoadingButton(true);

      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: newName,
        })
        .then(() => {
          updateBroker();
        })
        .catch(() => {
          setError("Error al actualizar el nombre");
          setIsLoadingButton(false);
        });
    }
  };

  /////////////////////////////
  // Funcion Actualizar Broker
  const updateBroker = async () => {
    let val_broker = await SRV.updateDatosBroker(
      firebase.auth().currentUser.uid,
      1,
      newName,
      newCompany,
      newTele,
      newCargo
    );
    if (val_broker.type > 0) {
      let new_brk = {
        brk_name: newName,
        brk_company: newCompany,
        brk_cargo: newCargo,
        brk_telefono: newTele,
      };
      await updateItem(USER_INFO, JSON.stringify(new_brk));
      setIsLoadingButton(false);
      //setReloadData(true);
      OpenModalEditPerfil(false);
      showNotification(
        4,
        "ACTUALIZACION EXITOSA",
        "Datos actualizados correcatmente"
      );
    } else {
      setIsLoadingButton(false);
      alert(val_broker.message);
    }
  };
  //////////////////////////////////////////
  //////////////////////////////////////////

  //////////////////////////////////////////
  /////// FUNCIONES PARA ACTUALIZAR AVATAR
  // Funcion para almacenar valores de Imagen a Actualizar del Avatar
  const onDrop = picture => {
    setPictures(picture);
  };

  const changeAvatar = async () => {
    var file = URL.createObjectURL(pictures[0]);

        uploadImage(file, uid).then(() => {
          //Imagen subida correctamente
          updatePhotoUrl(uid);
        });

  };


  const uploadImage = async (uri, nameImage) => {
    setIsLoadingAvatar(true);
    const response = await fetch(uri);
    const blob = await response.blob();

    const ref = firebase.storage().ref().child(`avatar/${nameImage}`);
    return ref.put(blob);
  };

  const updatePhotoUrl = (uid) => {
    firebase
      .storage()
      .ref(`avatar/${uid}`)
      .getDownloadURL()
      .then(async (result) => {
        const update = {
          photoURL: result,
        };
        await firebase.auth().currentUser.updateProfile(update);
        updateAvatarBroker();
      })
      .catch(() => {
        //Error descargando la foto
        showNotification(
          2,
          "ERROR DE ACTUALIZACION",
          "Error al descargar la foto"
        );
      });
  };

  const updateAvatarBroker = async () => {
    const user = firebase.auth().currentUser;
    let val_broker = await SRV.updateAvatar(
      firebase.auth().currentUser.uid,
      user.photoURL
    );
    if (val_broker.type > 0) {
      let new_foto = { brk_avatar: user.photoURL };
      await updateItem(USER_INFO, JSON.stringify(new_foto));
      console.log(val_broker.broker);
      //setReloadData(true);
      setIsLoadingAvatar(false);
      OpenModalAvatar(false);
      showNotification(
        4,
        "ACTUALIZACION EXITOSA",
        "Avatar Actualizado Exitosamente."
      );
    } else {
      showNotification(
        2,
        "ERROR DE ACTUALIZACION",
        "Error"
      );
    }
  };
  /////////////////////////////////////////
  /////////////////////////////////////////

  ////////////////////////////////////////////
  // Funcion para actualizar password
  const updateNewPassword = async () => {
    setError({});
    if (!antPassword || !password || !newPassword) {
      let objError = {};
      !newName && (objError.nombre = "No puede estar vacio");
      !newCompany && (objError.company = "Debe registrar la compañia");
      !newCargo && (objError.cargo = "Registre el cargo ocupado");
      !newTele && (objError.telefono = "Registre numero de contacto");
      setError(objError);
    } else {
      setIsLoadingButton(true);

          /*setIsLoadingButton(false);*/
        
    }
  };

  // Listado de Info del Usuario Logueado
  const data = [
    {
      title: newCompany,
      icon: mdiShieldAccountOutline,
    },
    {
      title: newCargo,
      icon: mdiSeatReclineExtra,
    },
    {
      title: newTele,
      icon: mdiPhone,
    },
    {
      title: newCiudad,
      icon: mdiGoogleMaps,
    },
  ];

  const ShowAlerts = (
    <form
      style={{
        backgroundColor: "#ffffff",
        marginTop: "25px",
        width: "350px",
        padding: "10px",
      }}
    >
      <div>
        <div
          style={{
            backgroundImage: `url('${bg3}')`,
            height: "140px",
            backgroundSize: "cover",
            padding: "2rem 1.5rem",
            color: "#ffffff",
          }}
        >
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <Icon
                path={mdiPencilCircle}
                title="Me"
                size={0.8}
                color={"#fff"}
              />
            }
            onClick={ModalChangeAvatar}
          >
          <Avatar className="avatar-dropdown" src={avatar} />
          </Badge>
          <span className="dropdown-name">{newName}</span>
        </div>

        <div className="form-dropdown-perfil">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Icon path={item.icon} size={0.8} color={"#c2c2c2"} />
                  }
                  title={item.title}
                />
              </List.Item>
            )}
          />

          <Form.Item>
            <Button
              className="form-edit-perfil__form-button"
              style={{ backgroundColor: colorPrm }}
              onClick={ModalEditPerfil}
            >
              Actualizar Perfil
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="form-edit-perfil__form-button"
              style={{ backgroundColor: colorPrm }}
              onClick={ModalChangePassword}
            >
              Actualizar Password
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="form-edit-perfil__form-button"
              style={{ backgroundColor: colorSec }}
              onClick={LogOut}
            >
              Cerrar sesión
            </Button>
          </Form.Item>
        </div>
      </div>
    </form>
  );

  return (
    <div className="main-header">
      <span style={{ left: "10px", position: "absolute" }}>
        <img src={logo_menu_top} className="img-logo-title" />
      </span>
      <span
        className="avatar-header"
        style={{ right: "10px", position: "absolute" }}
      >
        <Dropdown
          disabled={!currBroker}
          overlay={ShowAlerts}
          trigger={["click"]}
          placement="bottomLeft"
          className="dropdown-edit-header"
        >
            <Avatar src={avatar} />
        </Dropdown>
      </span>

      { /* MODAL DE ACTUALIZAR DATOS DEL PERFIL */}
      {stateModalPerfilEdit? (
        <Modal>
          <div className="form-edit-perfil">
            <Form.Item help={error.nombre}>
              <Input
                placeholder="Nombre"
                className="form-edit-perfil__form-input"
                onChange={(e) => setNewName(e.target.value)}
                defaultValue={newName}
                suffix={
                  <Icon
                    path={mdiAccountCircleOutline}
                    size={0.8}
                    color={"#c2c2c2"}
                  />
                }
                help={error.nombre}
              />
            </Form.Item>

            <Form.Item help={error.company}>
              <Input
                placeholder="Compañía"
                className="form-edit-perfil__form-input"
                onChange={(e) => setNewCompany(e.target.value)}
                defaultValue={newCompany}
                suffix={
                  <Icon
                    path={mdiShieldAccountOutline}
                    size={0.8}
                    color={"#c2c2c2"}
                  />
                }
              />
            </Form.Item>

            <Form.Item help={error.telefono}>
              <Input
                placeholder="Telefono"
                className="form-edit-perfil__form-input"
                onChange={(e) => setNewTele(e.target.value)}
                defaultValue={newTele}
                suffix={<Icon path={mdiPhone} size={0.8} color={"#c2c2c2"} />}
              />
            </Form.Item>
            <Form.Item help={error.cargo}>
              <Input
                placeholder="Cargo que Ocupa"
                className="form-edit-perfil__form-input"
                onChange={(e) => setNewCargo(e.target.value)}
                defaultValue={newCargo}
                suffix={
                  <Icon
                    path={mdiSeatReclineExtra}
                    size={0.8}
                    color={"#c2c2c2"}
                  />
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="form-edit-perfil__form-button"
                style={{ backgroundColor: colorPrm }}
                onClick={updateNewName}
                loading={isLoading}
              >
                Actualizar Perfil
              </Button>
            </Form.Item>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      { /* MODAL DE ACTUALIZAR AVATAR */}
      {stateModalEditAvatar ? (
        <Modal>
          <div className="form-edit-perfil">
            <Form.Item>



              <ImageUploader
                withIcon={true}
                withPreview={true}
                singleImage={true}
                label=""
                buttonText="Buscar Imagen"
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                maxFileSize={1048576}
                fileSizeError=" file size is too big"
              />



            </Form.Item>
            <Form.Item>
              <Button
                className="form-edit-perfil__form-button"
                style={{ backgroundColor: colorPrm }}
                onClick={changeAvatar}
                loading={isLoadingAvatar}
              >
                Actualizar Avatar
              </Button>
            </Form.Item>
          </div>
        </Modal>
      ) : (
        <></>
      )}


      { /* MODAL DE ACTUALIZAR PASSWORD */}
      {stateModalChangePassword? (
        <Modal>
          <div className="form-edit-perfil">
            <Form.Item help={error.password_ant}>
              <Input.Password
                placeholder="Password Anterior"
                className="form-edit-perfil__form-input"
                onChange={(e) => setAntPassword(e.target.value)}
                defaultValue={""}
                iconRender={(visible) =>
                  visible ? (
                    <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
                  ) : (
                    <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
                  )
                }
              />
            </Form.Item>

            <Form.Item help={error.password_new}>
              <Input.Password
                placeholder="Nuevo Password"
                className="form-edit-perfil__form-input"
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={""}
                iconRender={(visible) =>
                  visible ? (
                    <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
                  ) : (
                    <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
                  )
                }
              />
            </Form.Item>
            
            <Form.Item help={error.password_new}>
              <Input.Password
                placeholder="Repetir Password"
                className="form-edit-perfil__form-input"
                onChange={(e) => setNewPassword(e.target.value)}
                defaultValue={""}
                iconRender={(visible) =>
                  visible ? (
                    <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
                  ) : (
                    <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="form-edit-perfil__form-button"
                style={{ backgroundColor: colorPrm }}
                onClick={updateNewPassword}
                loading={isLoading}
              >
                Actualizar Password
              </Button>
            </Form.Item>
          </div>
        </Modal>
      ) : (
        <></>
      )}

    </div>
  );
}
