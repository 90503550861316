import React, {useState} from 'react';
import RegisterForm from "../Account/Register";
import AuthOptions from "../Account/AuthOptions";
import "./Auth.scss";
import logo from "../../assets/img/brokerhood.png";

const Auth = () => {

    const [selectedForm, setSelectedForm] = useState(null);
        
    const handler2_Form = () => {
      console.log(selectedForm);
      
        switch (selectedForm) {
          case "register":
            return <RegisterForm setSelectedForm={setSelectedForm}/>;
          default:
            return <AuthOptions setSelectedForm={setSelectedForm}/>;
        }
    };

    return (
      <div className="auth__box">
        <div>
          {handler2_Form()}
        </div>
      </div>
        
      );
}
 
export default Auth;
