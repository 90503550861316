import React, { useState } from "react";
import { Modal as ModalBH } from "antd";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { openCloseEditPerfilModalAction } from "../actions/modalActions";

export default function Modal(props) {
    const { children } = props;

    // Dispatch para ejecutar acciones de redux
    const dispatch = useDispatch();
    const OpenModal = state => dispatch(openCloseEditPerfilModalAction(state));
    const stateModal = useSelector(state => state.modals.stateModal);

    return (
        <ModalBH
          visible={stateModal}
          onOk={() => OpenModal(false)}
          onCancel={() => OpenModal(false)}
          closable={false}
          footer={false}
        >
        <p>{children}</p>
        </ModalBH>
      );

}