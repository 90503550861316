
export const saveItem = async (KeyName, KeyValue) => {
  try {
    await localStorage.setItem(KeyName, KeyValue);
    return true;
  } catch (e) {
    return false;
  }
};

export const updateItem = async (KeyName, KeyValue) => {
  try {
    await localStorage.mergeItem(KeyName, KeyValue);
    return true;
  } catch (e) {
    return false;
  }
};

export const getItem = async (KeyName) => {
  try {
    return await localStorage.getItem(KeyName);
  } catch (e) {
    return false;
  }
};

export const validItem = async (KeyName) => {
  try {
    return await localStorage.getItem(KeyName);
    //return true;
  } catch (e) {
    return false;
  }
};

export const deleteItem = async (KeyName) => {
  try {
    await localStorage.removeItem(KeyName);
    return true;
  } catch (e) {
    return false;
  }
};

export const clearAll = async () => {
  try {
    await localStorage.clear();
    return true;
  } catch (e) {
    return false;
  }
};
