import React, { useState, useEffect } from "react";
import { View, Container, Button, Link as Linkba, lightColors, darkColors } from "react-floating-action-button";
import { Icon, ActionButton}  from "@material-ui/core";
import Loading from "../../../reducers/LoadingReducers";
import MyBrokerHoodsForm from "../../../components/BrokerHood/MyBrokerHoodsForm";
import GestionGen from "../../../components/BrokerHood/GestionGen";
import { getItem } from "../../../utils/Storage";
import { USER_INFO, GESTION_GEN } from "../../../constants";
import { cargarBhoods } from "../../../utils/ReloadEnv";

export default function MyBrokerHoods(props) {
  const { navigation } = props;
  const [gestionGen, setGestionGen] = useState({});
  const [brokerInfo, setBrokerInfo] = useState({});
  const [brokerHoods, setBrokerHoods] = useState([]);
  const [inMemoryBhoods, setInMemoryBhoods] = useState([]);
  const [loguedBh, setLoguedBh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verGestion, setVerGestion] = useState(true);
  const [isReloadBrokerhoods, setIsReloadBrokerhoods] = useState(false);


  return (
    <React.Fragment>
      {verGestion ? (
       <h1> Ver Gestion </h1>
      ) : (
        <GestionGen navigation={navigation} gestionGen={gestionGen} />
      )}
      <h1>  </h1>
    </React.Fragment>
  );
}

function AddBrokerhoodBotton(props) {

  return (
    <h1> ADDBrokerHoodBotton </h1>
  );
}
