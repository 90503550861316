const initialProps = {
    statusLogin: false
};

export default function(state = initialProps, action) {
    switch (action.type) {
        case "STATE_STATUS_LOGIN":
            return {
                ...state,
                statusLogin: action.payload
            };
        default:
            return state;
    }
}