import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";

// Synfusion Upload
import {UploaderComponent, RemovingEventArgs} from '@syncfusion/ej2-react-inputs';
import {CheckBoxComponent, ChangeEventArgs} from '@syncfusion/ej2-react-buttons';
import { Browser } from '@syncfusion/ej2-base';
// Upload Syncfusion
import "./UploadInventory.scss";
import { PropertyPane } from "./property-pane";

// Importo iconos Material Community
import Icon from '@mdi/react'
import { mdiMicrosoftExcel } from '@mdi/js'

import {showNotification } from "../../utils/showNotification";
import SRV from "../../utils/Service";

// Excel
import { OutTable, ExcelRenderer } from "react-excel-renderer";


export default function UploadInventory() {
    const [uploadObj, setUploadObj] = useState({});
    const [checkboxObj, setCheckboxObj] = useState({});
    const [checkboxObj1, setCheckboxObj1] = useState({});

    const [asyncSettings, setAsyncSettings] = useState({
      saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
      removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
    });
    const [dropContainerEle, setDropContainerEle] = useState(null);
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {

      //uploadObj.dropArea = dropContainerEle;
      //uploadObj.element.setAttribute('name', 'UploadFiles');
      //uploadObj.dataBind();

    }, []);

    const fileHandler = async(event) => {

      let fileObj = event.target.files[0];
      let fileName = event.target.files[0];

      console.log(event.target.files[0]);
      let valuExcel = await SRV.uploadInventory(fileName);
      console.log(valuExcel);
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        
        if(err){
          console.log(err);
        }
        else{
  
          setCols(resp.cols);
          setRows(resp.rows);

        }
        
      });         
          
    }

    const dropContainerRef = (element) => {
      setDropContainerEle(element);
    }

    const onChange = (args) => {
      uploadObj.autoUpload = args.checked;
      setUploadObj({});
    }

    const onChanged = (args) => {
      uploadObj.sequentialUpload = args.checked;
      setUploadObj({});
    }

    const onRemoveFile = (args) => {
      args.postRawFile = false;
    }

    const onSuccess = (args) => {
/*
      let fileObj = args.target.file[0];
      let fileName = args.target.file[0];
*/
      console.log(args);

    }

    const onFileUpload = (args) => {
      // add addition data as key-value pair.
      args.customFormData = [{'name': 'Syncfusion INC'}];
      
    }

    return (
    <div className="upload-panel">
        <h2>Subir Inventario Mediante Excel</h2>
        <Form.Item>
        <div className="control-pane" ref={dropContainerRef}>
        <div className="control-section row uploadpreview">
         <div className="col-lg-9">
          <div className="upload_wrapper">
            {/* Render Uploader */}
            <UploaderComponent id="fileUpload" type="file" ref = {(scope) => {setUploadObj(scope)}}
             asyncSettings = {asyncSettings}
             removing= { onRemoveFile.bind(this) }
            allowedExtensions ={'.pdf, .png, .txt, .xlsx, .xls, .xlsm, .csv'}
            /*uploading={onFileUpload.bind(this)}*/
            ></UploaderComponent>
        </div>
        </div>
        <div className="property-section col-lg-3">
            <PropertyPane>

                    <CheckBoxComponent checked={true}  label="Auto Upload" ref={(scope) => { setCheckboxObj(scope); }} change={ onChange.bind(this) } ></CheckBoxComponent>
                    <CheckBoxComponent checked={false} label="Sequential Upload" ref={(scope) => { setCheckboxObj1(scope); }} change={ onChanged.bind(this) } ></CheckBoxComponent>

            </PropertyPane>
        </div>
        </div>
      </div>







EJEMPLO FUNCIONANDO
        <div className="e-file-select-wrap">
          <Input
            type={"file"}
            onChange={fileHandler.bind(this)}
            loading 
          />  
        </div>
        </Form.Item>

        <OutTable data={rows} columns={cols} tableClassName="e-headercell" tableHeaderRowClass="heading e-headercell" />
    </div>
    );

}