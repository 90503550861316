export const ERROR_FETCH = { type: 0, message: "ERROR INESPERADO" };
export const ACCESS_TOKEN = "@access-token";
export const USER_INFO = "@user-info";
export const USER_AVATAR = "@user-avatar";
export const USER_BHOODS = "@user-brokerhoods";
export const USER_BHOODS_INV = "@user-brokerhoods-inv";
export const USER_BHOODS_COMUNAS = "@user-brokerhoods-comunas";
export const USER_COMUNAS = "@user-comunas";
export const USER_ZONAS = "@user-zonas";
export const USER_MIEMBROS = "@user-members";
export const USER_OFERTAS = "@user-ofertas";
export const USER_PEDIDOS = "@user-pedidos";
export const USER_PEDIDOS_ZONAS = "@user-pedidos-zonas";
export const TIPOS_INMUEBLE = "@tipos-inmueble";
export const USER_BHOODS_ZONAS = "@user-brokerhoods-zonas";
export const USER_MATCHES_ORD = "@user-matches-ord";
export const USER_MATCHES_OFR = "@user-matches-ofr";
export const USER_MESSAGES = "@user-messages";
export const CURRENT_MATCHES = 1;
export const USER_MATCHES = "@user-matches";
export const USER_CATCHES = "@user-catches";
export const USER_HITS = "@user-hits";
export const COMUNAS_INV = "@comunas-inv";
export const MEMBERS_INV = "@members-inv";
export const OFERTAS_INV = "@ofertas-inv";
export const PEDIDOS_INV = "@pedidos-inv";
export const PEDIDOS_ZONAS_INV = "@pedidos-zonas-inv";
export const MATCHES_INV = "@matches-inv";
export const CATCHES_INV = "@catches-inv";
export const GESTION_GEN = "@gestion-gen";
export const AVATAR_DEFAULT =
  "http://2020.aal-estate.com/files/brokerhood/avatar/avatar-1.png";
export const URL_AVATAR = "http://2020.aal-estate.com/files/brokerhood/avatar/";
export const URL_OFFERT =
  "http://2020.aal-estate.com/files/brokerhood/estates/";
export const URL_SERVICE = "https://2020.aal-estate.com/api";

export const colorPrm = "#6A3E98";
export const colorSec = "#66C6F1";

// Posicion de l ShowNotification en la Pantalla
export const placementNotification = "topRight";
