import React, { useState, useEffect } from "react";
import { Button, Input, Form, Divider, Select } from "antd";
import SRV from "../../../utils/Service";

// Importo iconos Material Community
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiShieldAccountOutline,
  mdiAt,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiSeatReclineNormal,
  mdiPhone,
  mdiBlackMesa,
} from "@mdi/js";

// Constantes
import { showNotification } from "../../../utils/showNotification";
import { colorPrm } from "../../../constants";

import { toast } from "react-toastify";
import { validateEmail } from "../../../utils/Validation";
import firebase from "../../../utils/firebase";
import "firebase/auth";
import "firebase/firestore";

// Estilos
import "./Register.scss";

const Register = () => {
  const [isVisibleLoading, setIsVisibleLoading] = useState(false);
  const [ciudades, setCiudades] = useState([
    { label: "SELECCIONE LA CIUDAD", value: 0 },
  ]);
  const [formData, setFormData] = useState(defaultValueForm());
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const { Option } = Select;

  const db = firebase.firestore(firebase);

  useEffect(() => {
    (async () => {
      //  setIsVisibleLoading(false);
      let data = await SRV.getCiudades();
      setCiudades(data);
      console.log(data);
    })();
    //setIsVisibleLoading(false);
  }, []);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setFormError({});
    let errors = {};
    let formOK = {};

    if (
      formData.ciudad === "" ||
      formData.nombre === "" ||
      formData.cargo === "" ||
      formData.email === "" ||
      formData.telefono === "" ||
      formData.password.length === "" ||
      formData.repeatpassword.length === ""
    ) {
      showNotification(
        2,
        "CAMPOS EN BLANCO",
        "Todos los campos son obligatorios."
      );
    }

    if (!formData.ciudad) {
      errors.ciudad = true;
      formOK = false;
    }
    if (!formData.nombre) {
      errors.nombre = true;
      formOK = false;
    }
    if (!formData.cargo) {
      errors.cargo = true;
      formOK = false;
    }
    if (!validateEmail(formData.email)) {
      errors.email = true;
      formOK = false;
    }
    if (!formData.telefono) {
      errors.telefono = true;
      formOK = false;
    }
    if (formData.password !== formData.repeatpassword) {
      errors.password = true;
      formOK = false;
    }
    //        if(formData.repeatpassword.length < 6){
    //            errors.repeatpassword = true;
    //            formOK = false;
    //       }

    if (errors.email) {
      setIsError("Ingrese un email valido.");
    }

    if (errors.password) {
      showNotification(
        2,
        "ERROR EN PASSWORD",
        "Las contraseñas deben ser iguales."
      );
    }

    setFormError(errors);

    if (formOK) {
      setIsLoading(true);

      firebase
        .auth()
        .createUserWithEmailAndPassword(formData.email, formData.password)
        .then(() => {
          showNotification(4, "LOGUEADO", "Usuario registrado correctamente.");
          createBroker();
        })
        .catch(() => {
          showNotification(
            2,
            "ERROR EN REGISTRO",
            "Error, registrando usuario."
          );
        });
    }
  };

  //Funcion que crea el primer miembro del grupo
  const createBroker = async () => {
    const user = firebase.auth().currentUser.uid;
    let val_broker = await SRV.createBroker(
      user,
      formData.nombre,
      formData.compañia,
      formData.email,
      formData.telefono,
      formData.cargo,
      formData.ciudad
    );
    if (val_broker.type > 0) {
      console.log(val_broker);
      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: formData.nombre,
        })
        .catch(() => {
          showNotification(
            2,
            "ERROR EN NOMBRE",
            "Error actualizando nombre de usuario."
          );
          setIsVisibleLoading(false);
        });
    } else {
      setIsVisibleLoading(false);
      alert(val_broker.message);
    }
    const nombreusuario = firebase.auth().currentUser.displayName;
    console.log(nombreusuario);
  };

  return (
    <div className="register-form">
      <h1> Registro </h1>
      <Form onChange={onChange}>
        <Form.Item>
          <Select
            defaultValue="SELECCIONE"
            style={{ width: 120 }} 
            className="select"
            suffixIcon={<Icon size={0.8} color={"#666"} />}
            options={ciudades}
            bordered={false}
          /> 
        </Form.Item>
        <Form.Item>
          <Input
            name="nombre"
            placeholder="Nombre"
            className="input"
            suffix={<Icon path={mdiAccount} size={0.8} color={"#666"} />}
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="compañia"
            placeholder="Compañia"
            className="input"
            suffix={
              <Icon path={mdiShieldAccountOutline} size={0.8} color={"#666"} />
            }
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="cargo"
            placeholder="Cargo"
            className="input"
            suffix={
              <Icon path={mdiSeatReclineNormal} size={0.8} color={"#666"} />
            }
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="email"
            placeholder="Correo Electronico"
            error={formError.email}
            className="input"
            suffix={<Icon path={mdiAt} size={0.8} color={"#666"} />}
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="telefono"
            placeholder="Numero Telefónico"
            className="input"
            suffix={<Icon path={mdiPhone} size={0.8} color={"#666"} />}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            name="password"
            placeholder="Contraseña"
            className="input"
            iconRender={(visible) =>
              visible ? (
                <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
              ) : (
                <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
              )
            }
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            name="repeatpassword"
            placeholder="Repetir Contraseña"
            className="input"
            iconRender={(visible) =>
              visible ? (
                <Icon path={mdiEyeOutline} size={0.8} color={"#666"} />
              ) : (
                <Icon path={mdiEyeOffOutline} size={0.8} color={"#666"} />
              )
            }
          />
        </Form.Item>
        <Button
          className="register"
          style={{ backgroundColor: colorPrm }}
          type="submit"
          htmlType="button"
          block
          onClick={onSubmit}
          loading={isLoading}
        >
          Unirse a Brokerhood
        </Button>
      </Form>
    </div>
  );
};

export default Register;

// Inicializar variables
function defaultValueForm() {
  return {
    ciudad: "",
    nombre: "",
    compañia: "",
    cargo: "",
    email: "",
    telefono: "",
    password: "",
    repeatpassword: "",
  };
}
