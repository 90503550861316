import React from "react";
import { Button } from "antd";
import LoginForm from "../Login";

// Constantes
import { colorSec } from "../../../constants";

import "./AuthOptions.scss";
import logo from "../../../assets/img/brokerhood.png";

const AuthOptions = (props) => {
    const { setSelectedForm } = props;

  return (
    <div className="auth-options">
        <header>
          <h2>BIENVENIDO </h2>
        </header> 
        <div className="box-logo">
          <img src={logo} className="img-logo" />
        </div>
        <LoginForm />
        <Button className="register" style={{ backgroundColor: colorSec }} onClick={() => setSelectedForm("register")}> Unete a Brokerhood </Button>
    </div>
  );
};

export default AuthOptions;
//<Button className="login" onClick={() => setSelectedForm("login")} > Iniciar Sesión </Button>