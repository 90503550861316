const initialProps = {
    statusButtonUtils: false
};

export default function(state = initialProps, action) {
    switch (action.type) {
        case "STATUS_BUTTONS_UTILS":
            return {
                ...state,
                statusButtonUtils: action.payload
            };
        default:
            return state;
    }
}