import { combineReducers } from 'redux';
import statusIsLogin from './StatusLoginReducer';
import statusLoading from './LoadingReducers';
import buttonsutilsReducer from './ButtonsUtilsReducer';
import modalReducer from "./ModalReducer";

export default combineReducers({
    login: statusIsLogin,
    loading: statusLoading,
    buttonutils: buttonsutilsReducer,
    modals: modalReducer
});